<template>
  <h1 class="page-title">{{ pageTitle }}</h1>
</template>

<script>
export default {
  name: 'PageTitle',
  props: {
    item: null,
    property: {
      default: 'title'
    },
    checkTranslations: {
      default: true
    },
    prefix: {
      default: null
    }
  },
  computed: {
    title () {
      let title = null

      if (this.item) {
        const translation = this.getTranslation(this.item)

        if (Object.keys(translation).length > 0 && this.checkTranslations) {
          title = translation[this.property]
        } else {
          title = this.item[this.property]
        }
      }

      if (this.prefix) {
        return this.prefix + ' ' + title
      }

      return title
    },
    pageTitle () {
      let title = null

      if (this.title) {
        title = this.title
      } else {
        const nearestWithTitle = this.$route.matched.slice().reverse().find(r => r.meta && r.meta.title)
        title = this.$t(nearestWithTitle.meta.title)
      }

      document.title = title
      return title
    }
  }
}
</script>
