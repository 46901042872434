<template>
  <div>
    <header v-if="!isLoading" class="page-header">
      <PageTitle v-if="userTracking" :check-translations="false" prefix="User Tracking" :item="userTracking" property="id"/>
    </header>

    <div class="main-content">
      <div class="pane table-pane" v-if="userTracking">
        <formulate-input label="Module" :disabled="true" v-model="userTracking.module" type="text"></formulate-input>
        <formulate-input label="Pad" :disabled="true" v-model="userTracking.path" type="text"></formulate-input>
        <formulate-input label="Actie" :disabled="true" v-model="userTracking.action" type="text"></formulate-input>
        <formulate-input label="Gebruiker" :disabled="true" v-model="userTracking.user.email" type="text"></formulate-input>

        <div class="diff" v-if="userTracking.diff && userTracking.diff.iterable_item_added">
          <h3>Toegevoegde items:</h3>

          <div :key="item.key" v-for="(item, key) in userTracking.diff.iterable_item_added">
            <div class="diff-title">{{ key }}</div>

            <div class="diff-table-wrapper">
              <table class="diff-table">
                <thead>
                <tr>
                  <template v-for="(value, prop) in item">
                    <th :key="prop" v-if="typeof value !== 'object'">
                      {{ prop }}
                    </th>
                  </template>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <template v-for="(value, prop) in item">
                    <td :key="prop" v-if="typeof value !== 'object'">
                      {{ value }}
                    </td>
                  </template>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div class="diff" v-if="userTracking.diff && userTracking.diff.iterable_item_removed">
          <h3>Verwijderde items:</h3>

          <div :key="item.key" v-for="(item, key) in userTracking.diff.iterable_item_removed">
            <div class="diff-title">{{ key }}</div>

            <div class="diff-table-wrapper">
              <table class="diff-table">
                <thead>
                <tr>
                  <template v-for="(value, prop) in item">
                    <th :key="prop" v-if="typeof value !== 'object'">
                      {{ prop }}
                    </th>
                  </template>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <template v-for="(value, prop) in item">
                    <td :key="prop">
                      {{ value }}
                    </td>
                  </template>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div class="diff" v-if="userTracking.diff && userTracking.diff.values_changed">
          <h3>Aangepast:</h3>

          <div class="diff-table-wrapper">
            <table class="diff-table">
              <thead>
              <tr>
                <th>Veld</th>
                <th>Nieuwe waarde</th>
                <th>Oude waarde</th>
              </tr>
              </thead>
              <tbody>
              <tr :key="key" v-for="(row, key) in userTracking.diff.values_changed">
                <td>{{ key }}</td>
                <template v-for="(value, prop) in row">
                  <td :key="prop">
                    {{ value }}
                  </td>
                </template>
              </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="diff" v-if="userTracking.extra">
          <h3>Extra:</h3>

          <div :key="key" v-for="key in Object.keys(userTracking.extra)">
            <div class="diff-title">{{ key }}</div>
            {{ userTracking.extra[key] }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ApiService } from '@/services/admin/api.service'
import PageTitle from '@/components/admin/PageTitle'
import { DateTime } from 'luxon'

export default {
  name: 'Global',
  components: {
    PageTitle
  },
  data: function () {
    return {
      isLoading: true,
      id: this.$route.params.id,
      userTracking: null,
      datetime: DateTime
    }
  },
  async mounted () {
    this.global = { translations: [{ lang_code: this.activeLocale }] }

    if (this.id) {
      await ApiService.fetchUserTracking(this.id).then(res => {
        this.userTracking = res.data
      })
    }

    this.isLoading = false
  },
  methods: {}
}
</script>
